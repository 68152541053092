#search-drawer .ant-tabs-nav-list{
    padding: 0 2rem;
}

#search-drawer ul.search-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin: 1rem;
}
#search-drawer ul.search-list li{
    position: relative;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 1rem;
}
#search-drawer ul.search-list li.active{
    border-color: #3b9aff;
}
#search-drawer ul.search-list li .icon{
   position: relative;
}
#search-drawer ul.search-list li .icon img{
    height: 6rem;
    width: 6rem;
    object-fit: cover;
}
#search-drawer ul.search-list li .icon .anticon-check-circle{
    /* 已使用图标 */
    position: absolute;
    top: 0;
    right: 0;
    font-size: 2rem;
    color: #4a9f08;
}
#search-drawer ul.search-list li > .content{
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
}
#search-drawer ul.search-list li > .test-url{
    font-size: 1.5em;
}
#search-drawer ul.search-list li > .tag-group{
    position: absolute;
    top: 0.7rem;
    right: 0.5rem;
    display: flex;
    gap: 0.3rem;
    transition: all 0.3s;
    opacity: 0;
}
#search-drawer ul.search-list li:hover > .tag-group{
    opacity: 1;
}
#search-drawer ul.search-list li .ant-tag{
    cursor: pointer;
}

/* 自定义搜索表单样式 */
#search-drawer form{
    margin: 1.5rem;
}

#search-drawer form .upload-item{
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
    display: inline-block;
    transition: all 0.3s;
}
#search-drawer form .upload-item:hover{
   border-color:#3a90ff;
    color: #3a90ff;
}

#search-drawer form .upload-item .upload-button{
    width: 4rem;
    height: 4rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    user-select: none;
}

#search-drawer form .upload-item img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

#search-drawer form .upload-item .re-upload-button{
    /* 重新上传按钮 */
    position: absolute;
    background-color: #fff;
    padding: 0.5rem;
    border-radius: 50%;
    color: #666;
    opacity: 0;
    transition: all 0.3s;
}

#search-drawer form .upload-item:hover .re-upload-button{
    opacity: 1;
}
