/**
 重置样式
 */

:root{
  font-size: 14px;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}
body,ul,li,p,h1,h2,h3,h4,h5{
  margin: 0;
}
ul{
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
input{
  outline: none;
  border: none;
}
li{
  list-style: none;
}
button{
  outline: none;
  border: none;
  cursor: pointer;
}

/* 滚动条样式 */
::-webkit-scrollbar {
  width: 8px;
  height: 100%;
}
::-webkit-scrollbar-thumb {
  border: 0.5rem;
  width: 100%;
  border-radius: 0.25rem;
  background-color: #00000050;
}
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

.ant-drawer .close-btn{
  font-size: 2rem;
  display: block;
  color: black;
  margin: 4px auto;
  z-index: 10;
  transition: all 0.3s;
}
.ant-drawer .close-btn:hover{
  transform: rotate(90deg);
}

.ant-drawer .ant-drawer-content{
  overflow: unset;
}
.ant-drawer .ant-drawer-body {
  padding: 0;
}
.ant-drawer .ant-drawer-mask{
  background-color: rgba(0,0,0,0.1);
}