#wallpaper-list .nav-link{
    display: flex;
    justify-content: center;
    padding: 0.5rem 0;
    gap: 1rem;
}
#wallpaper-list .nav-link span{
    cursor:pointer
}
#wallpaper-list .nav-link span.active{
    color: #3a90fe;
}

#wallpaper-list ul{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: 8px;
}

#wallpaper-list ul li{
    position: relative;
    width: 320px;
    height: 160px;
    transition: all 0.3s;
    border-radius: 10px;
    overflow: hidden;
}

#wallpaper-list ul li:not(.more-btn):before{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0,0,0,0.4);
}
#wallpaper-list ul li:not(.more-btn):hover:before{
    display: none;
}

#wallpaper-list ul li img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor:pointer;
    transition: all 0.3s;
}
#wallpaper-list ul li:hover img{
    transform: scale(1.01);
}

#wallpaper-list ul li .btn{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    opacity: 0;
    transition: all 0.3s;
    background-color: rgba(0,0,0,0.4);
    padding: 0.5rem 1rem;
    color: #fff;
    border-radius: 5px;
    backdrop-filter: blur(5px);
}
#wallpaper-list ul li:hover .btn{
    opacity: 1;
}


#wallpaper-list ul li .anticon-close,
#wallpaper-list ul li .anticon-heart{
    position: absolute;
    top: 1rem;
    color:#fff;
    font-size: 1.5rem;
    transition: all 0.3s;
    cursor: pointer;
    opacity:0;
}
#wallpaper-list ul li .anticon-close{
    right: 1rem;
}
#wallpaper-list ul li .anticon-heart{
    left: 1rem;
}
#wallpaper-list ul li:hover .anticon-close{
    opacity: 1;
}
#wallpaper-list ul li .anticon-heart.fill{
    color: #f94d4f;
    opacity: 1;
}
#wallpaper-list ul li:hover .anticon-heart{
    opacity: 1;
}
#wallpaper-list ul.fav-list li .anticon-heart{
    opacity: 0;
}
#wallpaper-list ul.fav-list li:hover .anticon-heart{
    opacity: 1;
}

#wallpaper-list ul > div:last-child{
    display: flex;
    gap: 10px;
}

#wallpaper-list ul .more-btn{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.25rem;
    border-radius: 10px;
    border: 1px solid;
    color: #666;
}
#wallpaper-list ul li.more-btn{
    font-size: 1.2rem;
}
#wallpaper-list ul .more-btn:not(li){
    font-size: 14px;
    width: 155px;
    height: 90px;
}
#wallpaper-list ul .more-btn:not(li) .anticon{
    font-size: 3rem;
}
#wallpaper-list ul .more-btn .anticon{
    font-size: 4rem;
}
