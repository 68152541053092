#wallpaper{
    width: 100vw;
    height:100vh;
    position:fixed;
    left: 0;
    top: 0;
}
#wallpaper iframe{
    /*position: absolute;*/
    width: 100%;
    height: 100%;
    z-index: -10;
}
#wallpaper .background{
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: cover;
}
#wallpaper .mask{
    background-color: rgba(0,0,0,0.3);
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
}
#wallpaper .mask.focus{
    background-color: rgba(0,0,0,0.4);
}
#wallpaper button {
    margin-top: 100px;
}
