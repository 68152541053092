#setting .ant-tabs-tab{
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    margin: 0;
}
#setting .ant-tabs-nav{
    border-bottom: 1px solid #ccc;
}
#setting .ant-tabs-nav-list{
    padding: 0 2rem;
}

#setting .card{
    margin: 0.5rem 0.5rem 2rem 0.5rem;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
    border-radius: 0.5rem;
    overflow: hidden;
}
#setting .card header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ccc;
    padding:0.6rem 1rem;
    cursor: pointer;
    user-select: none;
}
#setting .card header .anticon-down{
    transition: transform 0.5s;
}
#setting .card.mini header .anticon-down{
    transform: rotate(180deg);
}
#setting .card main{
    padding: 1rem;
}

#setting .slider,
#setting .select,
#setting .switch{
    display: flex;
    gap: 0.5rem;
    margin: 1.5rem 0;
    align-items: center;
}
#setting .ant-radio-group .ant-space-item{
    margin: 0.5rem 0;
}
#setting .ant-radio-group .ant-radio-wrapper:hover > span{
   transition: color 0.3s;
}
#setting .ant-radio-group .ant-radio-wrapper.ant-radio-wrapper-checked > span,
#setting .ant-radio-group .ant-radio-wrapper:hover > span{
    color:#40a9ff
}
#setting .ant-radio-group .ant-radio-wrapper:hover .ant-radio .ant-radio-inner{
    border-color:#40a9ff
}
#setting .slider input[type=range]{
    flex: 1;
}
#setting .slider span:first-child{
    width: 4rem;
    text-align: center;
}
#setting .slider span:last-child{
    width: 4rem;
    text-align: right;
}
#setting .select > span{
    width: 5rem;
}
#setting .select .ant-select{
    flex:1;
    margin-right: 2rem;
}
#setting .simple-btn{
    width: 10rem;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}
#setting .select[disabled]{
   color: rgba(0,0,0,0.5);
}

#setting-navigation,
#setting-search,
#setting-system{
    height: calc(100vh - 64px);
    overflow: auto;
}

/* 未登录样式 */
#user-box.not-logged main{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
}
#user-box.not-logged main button{
    width: 6rem;
    height: 6rem;
    display: flex;
    margin: 1rem;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: 0 0 1rem rgba(0,0,0,0.4);
    font-size: 3rem;
    color: #fff;
    background-color: #444;
}
#user-box.not-logged main h3{
    font-size: 1.4rem;
    font-weight: 500;
}
#user-box.not-logged main p{
    color: #666;
}

/* 登录后样式 */
#user-box.logged main{
    display: flex;
    flex-direction: column;
    margin: 1rem;
}
#user-box.logged .info{
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    gap: 1rem;
    margin-bottom: 2rem;
}
#user-box.logged .info .avatar{
    width: 80px;
    height: 80px;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
    border-radius: 50%;
    overflow: hidden;
}
#user-box.logged .info .avatar img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
#user-box.logged .info >div{
   /*flex: 1;*/
}
#user-box.logged .info h3{
    font-size: 1.7rem;
    width: 8rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
#user-box.logged .info .ant-tag{
    position: relative;
    cursor: pointer;
    border-color: currentColor;
}
#user-box.logged .info .ant-tag:hover{
    color: #3a90fe;
}
#user-box.logged .info .ant-tag.loading{
    color: rgba(0,0,0,0.25);
    cursor:not-allowed;
}
#user-box.logged .info .ant-tag.loading .ant-spin{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}
#user-box.logged .info .ant-tag.loading .ant-spin .anticon-loading{
    font-size: 1.1rem;
}
#user-box.logged .info .anticon-login{
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1.5rem;
    cursor:pointer;
}
#user-box.logged .info p.tip{
    position: absolute;
    margin-top: 0.5rem;
}
#user-box.logged .info p.tip.info{
    color: #3a90ff;
}
#user-box.logged .info p.tip.success{
    color: #52c41a;
}
#user-box.logged .info p.tip.error{
    color: #f94d4f;
}
#user-box.logged .info p.tip.warning{
    color: #f9ad15;
}

#setting-system .ant-space.btn-group .ant-btn{
    width: 145px;
}

/*打赏样式*/
#setting-system .giveMoney img{
    margin-top: 1em;
    width: 100%;
}