#navigation{
    position: absolute;
    left: 50%;
    transform:translateX(-50%);
    top: calc(20vh + 62px);
    width: var(--navigation-width);
}
#navigation header{
    color: #fff;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 5px;
}
#navigation header{
    user-select: none;
    transition: transform 0.3s;
}
#navigation header .anticon-up{
    margin-left: 0.5em;
    transition: all 0.3s;
    padding: 0.5em;
    border-radius: 50%;
}
#navigation header.mini .anticon-up{
    transform: rotate(180deg);
}
#navigation header .anticon-up:hover{
    background: rgba(255,255,255);
    color: #000;
}


/* 导航分页小圆点 */
#navigation-box .slick-dots{
    bottom: -2rem;
    opacity: var(--navigation-opacity);
}
#navigation-box .slick-dots:hover{
    opacity: 1;
}
#navigation-box .slick-dots li{
    height: 1rem;
    width: 1rem;
    margin: 0.5rem;
}
#navigation-box .slick-dots button{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transition: unset;
}
/* 页面 */
#navigation-box ul.wrap{
    position: relative;
    border-radius: 5px;
    margin-top: 0.25rem;
    display: grid;
    grid-template-columns: repeat(auto-fill,var(--navigation-size));
    grid-template-rows: repeat(auto-fill,calc(var(--navigation-size) + var(--navigation-font-size) * 2));
    grid-auto-flow: dense;
    row-gap: var(--navigation-space-y);
    column-gap: var(--navigation-space-x);
    justify-content: center;
    align-items: start;
    height: var(--navigation-height);
    width: var(--navigation-width);
}
#navigation-box ul.wrap li{
   opacity: var(--navigation-opacity);
    transition: opacity 0.3s;
}
#navigation-box ul.wrap li:hover{
    opacity: 1;
}
#navigation-box .icon{
    /* 书签图标 */
    cursor: pointer;
    position: relative;
    border-radius: 50%;
    width: var(--navigation-size);
    height: var(--navigation-size);
    display: flex;
    justify-content: center;
    align-content: center;
}
#navigation-box ul.wrap li > span{
    /* 书签名称 */
    cursor: pointer;
    display:block;
    text-align: center;
    color: #fff;
    font-size: var(--navigation-font-size);
    height: 2em;
    line-height: 2em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

#navigation-box .icon > div,
#navigation-box .icon img{
    /* 书签图标(图片｜纯色) */
    width: 100%;
    height: 100%;
    border-radius: var(--navigation-rounded);
    object-fit: cover;
    transition: box-shadow 0.2s;
}
#navigation-box .icon > div:hover,
#navigation-box .icon img:hover{
    box-shadow: 0 0 10px 2px rgba(0,0,0,0.3);
}

#navigation-box .icon > div{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2em;
    background: rgba(255,255,255,0.5);
    color: #333;
}

#navigation-box .icon .anticon-close{
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    border-radius: 50%;
    padding: 3px;
    color: #fff;
    animation: wiggle 0.3s infinite;
    transition: color 0.2s , background-color 0.2s;
    z-index: 1;
}

#navigation-box .icon .anticon-close:hover{
    background-color: rgba(255,255,255,0.5);
    color: #000;
    cursor: pointer;
}

#navigation-box .icon .anticon-form{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    opacity: 0;
    border-radius: 50%;
    height: calc(var(--navigation-size) * 0.6);
    width: calc(var(--navigation-size) * 0.6);
    font-size: calc(var(--navigation-size) * 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #888;
    transition: opacity 0.3s, box-shadow 0.3s, background-color 0.3s;
}
#navigation-box .icon .anticon-form:hover{
    opacity: 1;
    box-shadow: 0 0 10px rgba(0,0,0,0.3);
    background-color: rgba(255,255,255,1);
}
#navigation-box .empty-navigation{

}

/* 拖拽时翻页放置DOM */
#drag-drop-container li{
    position: fixed;
    top: 0;
    height: 100%;
    width: 5vw;
    backdrop-filter: blur(5px);
}
#drag-drop-container li.left{
    left: 0;
    transform: translateX(-100%);
    border-radius: 50% 0 0 50%;
    background:linear-gradient(90deg,transparent,rgba(255,255,255,0.1));
}
#drag-drop-container li.right{
    right: 0;
    transform: translateX(100%);
    border-radius:0 50% 50% 0;
    background:linear-gradient(90deg,rgba(255,255,255,0.1),transparent);
}

/**
 * 导航书签修改Modal框样式
 */
.navigation-modify-container .navigation-icon .ant-form-item-control-input-content{
    display: flex;
    gap: 1rem;
}
.navigation-modify-container .navigation-icon .icon,
.navigation-modify-container .navigation-icon .upload-button,
.navigation-modify-container .navigation-icon .match-button{
    width: 6rem;
    height: 6rem;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.navigation-modify-container .navigation-icon .icon{
    position: relative;
    background-color: var(--bg-color);
    color: #fff;
    font-size: 3rem;
    cursor:pointer;
}
.navigation-modify-container .navigation-icon img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.navigation-modify-container .navigation-icon .upload-button,
.navigation-modify-container .navigation-icon .match-button{
    border: 1px solid currentColor;
    color: #888;
    transition: 0.3s color;
}
.navigation-modify-container .navigation-icon .upload-button:hover,
.navigation-modify-container .navigation-icon .match-button:hover{
    color: #333;
}
.navigation-modify-container .navigation-icon .upload-input{
    display: none;
}
.navigation-modify-container .navigation-icon .icon{
    position: relative;
    cursor:unset;
}
.navigation-modify-container .navigation-icon .icon button{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    border-radius: 3px;
    background: rgba(0,0,0,0.7);
    padding: 2px 5px;
    font-size: 1rem;
}
.navigation-modify-container .ant-input.name,
.navigation-modify-container .ant-input.url{
    border: none;
    border-bottom:1px solid #d9d9d9;
    color: #666;
}
.navigation-modify-container .ant-input.name:focus,
.navigation-modify-container .ant-input.url:focus{
    border: none;
    color: #000;
}
