@keyframes wiggle {
    0%,
    100% {
        transform: rotate(5deg);
    }
    50% {
        transform: rotate(-5deg);
    }
}

@keyframes rotating {
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}
