#login-form.fixed{
    position: fixed;
}
#login-form.absolute {
    position: absolute;
}
#login-form{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.3);
    z-index: 99999;
}
#login-form.absolute .content{
    position: absolute;
    width: calc(100% - 4rem);
}
#login-form.fixed .content{
    position: fixed;
    width: 300px;
    padding: 1rem 2rem;
}
#login-form .content{
    left: 50%;
    top: 30%;
    transform: translate(-50%,-50%);
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 10px 2px rgba(0,0,0,0.3);
}
#login-form .content .anticon-close{
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    font-size: 1.2rem;
    transition: all 0.3s;
}
#login-form .content .anticon-close:hover{
    transform: scale(1.03);
}
#login-form .login-content{
    display: flex;
    justify-content: center;
}
#login-form .login-qq{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 1rem;
    cursor: pointer;
}
#login-form .login-qq .anticon{
    font-size: 6rem;
    color: #58f;
}
#login-form .login-content .login-loading{
    display: flex;
    height: 100%;
    flex-direction: column;
    gap: 2rem;
    justify-content: center;
    align-items: center;
}
#login-form .login-content .login-loading.qq .anticon-loading{
    font-size: 3rem;
}
