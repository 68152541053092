input[type=range]{
    appearance: none;
    cursor: pointer;
}
input[type=range]::-webkit-slider-thumb{
    appearance: none;
    /*background-color: #91d5ff;*/
    background-color: #3a90ff;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    margin-top: -4px;
    transition: all 0.2s;
    position: relative;
}
input[type=range]:hover::-webkit-slider-thumb{
    box-shadow: 0 0 10px #3a90ff;
}
input[type=range]::-webkit-slider-runnable-track {
    background-color: rgba(0,0,0,0.2);
    height: 8px;
    border-radius: 4px;
    transition: all 0.2s;
}
input[type=range]::-webkit-slider-runnable-track:hover {
    background-color: #a9bdc9;
}
